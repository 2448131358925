import React from "react"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import aboutStyles from "../styles/pages/about.module.css"
import aboutHeader from "../images/about-header-img.png"

import side1 from "../images/side-img-1.png"
import side2 from "../images/side-img-2.png"
import side3 from "../images/side-img-3.png"
import side4 from "../images/side-img-4.png"
import side5 from "../images/side-img-5.png"
import side6 from "../images/side-img-6.png"
import side7 from "../images/side-img-7.png"
import side8 from "../images/side-img-8.png"

const AboutPage = ({ data }) => (
  <Layout  location="about">
    <SEO title="About" />
    <div className={ aboutStyles.header }>
      <img src={ aboutHeader } alt=""/>
    </div>
    <div className={ aboutStyles.grid }>
      <h1>About</h1>
      <div className={ aboutStyles.leftSide }>
        <div className={ aboutStyles.sideImg }><img src={ side1 } /></div>
        <div className={ aboutStyles.sideImg }><img src={ side2 } /></div>
        <div className={ aboutStyles.sideImg }><img src={ side3 } /></div>
        <div className={ aboutStyles.sideImg }><img src={ side4 } /></div>
      </div>
      <div className={ aboutStyles.aboutText }>
        <div className={ aboutStyles.description }>
          <p>Executive Decisions is an integrated marketing communications (IMC) firm that provides either full or specific marketing services to clients belonging to different business industries.</p>
          <p>Executive Decisions easily adapts to the client's business ways of working for a seamless project integration and collaboration.</p>
        </div>
        <div className={ aboutStyles.missionStatement }>
          <h3>Mission Statement</h3>
          <p>Executive Decisions IMC Firm is an integrated marketing communications company commited to providing clients a prime level of service, attaining cost effectiveness and excellence in the formulation and execution of their marketing undertakings to help sharpen their competitive edge.</p>
          <p>It shall earnestly cultivate partnerships with clients to bring around better products and services for the benefit of their customers towards the attainment of better life.</p>
        </div>
        <div className={ aboutStyles.visionStatement }>
          <h3>Vision Statement</h3>
          <p>To be a perennially dynamic self-evolving company serving as the go-to IMC agency of clients for their marketing communication needs.</p>
        </div>
      </div>
      <div className={ aboutStyles.rightSide }>
        <div className={ aboutStyles.sideImg }><img src={ side5 } /></div>
        <div className={ aboutStyles.sideImg }><img src={ side6 } /></div>
        <div className={ aboutStyles.sideImg }><img src={ side7 } /></div>
        <div className={ aboutStyles.sideImg }><img src={ side8 } /></div>
      </div>
      <div className={ aboutStyles.milestones }>
        <div className={ aboutStyles.sectionTitle }>
          <div className={ aboutStyles.icon }><Img fluid={data.icon1.childImageSharp.fluid} /></div>
          <h3>Milestones</h3>
        </div>
        <table>
          <tr>
            <td>1998</td>
            <td>Executive Decisions was founded on February 02, 1998. Advocating IMC (Integrated Marketing Communications) which was then a relatively novel concept.</td>
          </tr>
          <tr>
            <td>2000</td>
            <td>Executive Decisions was incorporated into EDIMCF Corp.</td>
          </tr>
          <tr>
            <td>2001</td>
            <td>Team ED was formed to brand and focus on the company’s banner service of professional events management.</td>
          </tr>
          <tr>
            <td>2004</td>
            <td>Regional offices in Luzon, Visayas and Mindanao were installed for nationwide and local execution of projects.</td>
          </tr>
          <tr>
            <td>2005</td>
            <td>True North ArtHouse graphic and video arts unit is formed to strengthen the creative services.</td>
          </tr>
          <tr>
            <td>2006</td>
            <td>T.E.A.M.P.L.A.Y. promo and activations unit formed to accommodate staffing and field deployment, and promos and brand activations and be given the full attention they deserve.</td>
          </tr>
          <tr>
            <td>2007</td>
            <td>ED Talents & Events is created to oversee talent sourcing and handling, providing various talents for shoots, speaking engagements, guest performances, casting, print modelling.</td>
          </tr>
          <tr>
            <td>2010</td>
            <td>ED Talents & Events becomes Flair! Talent Bureau.</td>
          </tr>
          <tr>
            <td>2011</td>
            <td>ED forms strategic alliance with then pioneering Outpost Visual Frontier to form ED/Outpost prod & post-prod house.</td>
          </tr>
          <tr>
            <td>2012</td>
            <td>ED starts to get international clients, primarily based in HK and Singapore.</td>
          </tr>
          <tr>
            <td>2014</td>
            <td>Alliances with like-minded agencies in South East Asia are forged.</td>
          </tr>
          <tr>
            <td>2015</td>
            <td>ED transfers its main offices to its own The IMC Block building.</td>
          </tr>
          <tr>
            <td>2018</td>
            <td>ED evolved its prod & postprod house to Creative Caboose, a very dynamic startup.</td>
          </tr>
          <tr>
            <td>2019</td>
            <td>ED steps up with digital services.</td>
          </tr>
          <tr>
            <td>2020</td>
            <td>A dawn of new challenges waiting to be conquered and celebrated.</td>
          </tr>
        </table>
      </div>
      <div className={ aboutStyles.affiliations }>
        <div className={ aboutStyles.sectionTitle }>
          <div className={ aboutStyles.icon }><Img fluid={data.icon2.childImageSharp.fluid} /></div>
          <h3>Affiliations</h3>
        </div>
        <div className={ aboutStyles.logos }>
          <div className={ aboutStyles.logo }><Img fluid={ data.image1.childImageSharp.fluid } /></div>
          <div className={ aboutStyles.logo }><Img fluid={ data.image2.childImageSharp.fluid } /></div>
          <div className={ aboutStyles.logo }><Img fluid={ data.image3.childImageSharp.fluid } /></div>
          <div className={ aboutStyles.logo }><Img fluid={ data.image4.childImageSharp.fluid } /></div>
        </div>
        <div className={ aboutStyles.logos }>
          <div className={ aboutStyles.logo }><Img fluid={ data.image5.childImageSharp.fluid } /></div>
          <div className={ aboutStyles.logo }><Img fluid={ data.image6.childImageSharp.fluid } /></div>
          <div className={ aboutStyles.logo }><Img fluid={ data.image7.childImageSharp.fluid } /></div>
          <div className={ aboutStyles.logo }><Img fluid={ data.image8.childImageSharp.fluid } /></div>
        </div>
        <div className={ aboutStyles.logos }>
          <div className={ aboutStyles.logo }><Img fluid={ data.image9.childImageSharp.fluid } /></div>
          <div className={ aboutStyles.logo }><Img fluid={ data.image10.childImageSharp.fluid } /></div>
          <div className={ aboutStyles.logo }><Img fluid={ data.image11.childImageSharp.fluid } /></div>
          <div className={ aboutStyles.logo }><Img fluid={ data.image12.childImageSharp.fluid } /></div>
        </div>
      </div>
    </div>
  </Layout>
)

export const background = graphql`
  fragment background on File {
    childImageSharp {
      fluid(maxWidth: 2100) {
        aspectRatio
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const logo = graphql`
  fragment logo on File {
    childImageSharp {
      fluid(maxWidth: 150) {
        aspectRatio
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const icon = graphql`
  fragment icon on File {
    childImageSharp {
      fluid(maxWidth: 200) {
        aspectRatio
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const query = graphql`
  query {
    header: file(relativePath: { eq: "about-header-img.png" }) {
      ...background
    }
    icon1: file(relativePath: { eq: "milestones.png" }) {
      ...icon
    }
    icon2: file(relativePath: { eq: "affiliations.png" }) {
      ...icon
    }
    image1: file(relativePath: { eq: "ADVERTISING FOUNDATION.png" }) {
      ...logo
    }
    image2: file(relativePath: { eq: "AMA LOGO.png" }) {
      ...logo
    }
    image3: file(relativePath: { eq: "AMIC LOGO.png" }) {
      ...logo
    }
    image4: file(relativePath: { eq: "ASAP logo.png" }) {
      ...logo
    }
    image5: file(relativePath: { eq: "ISM.png" }) {
      ...logo
    }
    image6: file(relativePath: { eq: "pasia.png" }) {
      ...logo
    }
    image7: file(relativePath: { eq: "PCAA.png" }) {
      ...logo
    }
    image8: file(relativePath: { eq: "PMA logo.png" }) {
      ...logo
    }
    image9: file(relativePath: { eq: "PMAP logo.png" }) {
      ...logo
    }
    image10: file(relativePath: { eq: "PSTD.png" }) {
      ...logo
    }
    image11: file(relativePath: { eq: "VSR MARCOMM OPC.png" }) {
      ...logo
    }
    image12: file(relativePath: { eq: "WMA.png" }) {
      ...logo
    }
  }
`

export default AboutPage